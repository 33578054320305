import * as React from "react";
import { useFormContext } from "react-hook-form";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

const LoadingSpinnerOverlay = () => {
    const {
        formState: { isSubmitting }
    } = useFormContext();

    if (!isSubmitting) return null;

    return (
        <Box
            bgcolor="#EBEBE1"
            position="absolute"
            top={0}
            bottom={0}
            left={0}
            right={0}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
        >
            <CircularProgress sx={{ mb: 2 }} />
            <Typography variant="h6" color="primary.main">
                Please wait...
            </Typography>
        </Box>
    );
};

export default LoadingSpinnerOverlay;
