import * as React from "react";
import PropTypes from "prop-types";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import FormHelperText from "../FormHelperText";

const TextField = React.forwardRef(({ label, error, helperText, ...rest }, ref) => (
    <FormControl {...{ ref }}>
        <FormLabel>{label}</FormLabel>
        <OutlinedInput {...rest} />
        {helperText && <FormHelperText {...{ error }}>{helperText}</FormHelperText>}
    </FormControl>
));

TextField.propTypes = {
    label: PropTypes.string.isRequired,
    error: PropTypes.bool,
    helperText: PropTypes.string
};

TextField.defaultProps = {
    error: false,
    helperText: null
};

export default TextField;
