import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { ThemeProvider } from "@mui/material/styles";

import SEO from "../components/SEO";
import Layout from "../components/Layout";
import ContactForm from "../components/ContactForm";
import CityRoad from "../images/CityRoad.svg";
import { darkTheme } from "../gatsby-theme-material-ui-top-layout/theme";

const ContentStrip = ({ children, image }) => (
    <Layout.Content
        BoxProps={{
            sx: {
                py: {
                    xs: 2,
                    sm: 8,
                    md: 15
                }
            }
        }}
    >
        <Box
            display="flex"
            justifyContent="space-between"
            sx={{ flexDirection: { xs: "column", sm: "row" } }}
        >
            <Box>{children}</Box>
            <Box display="flex" justifyContent="center" alignItems="center">
                {image}
            </Box>
        </Box>
    </Layout.Content>
);

ContentStrip.propTypes = {
    children: PropTypes.node.isRequired,
    image: PropTypes.object.isRequired
};

const GetInTouch = () => (
    <Layout title="Get in touch">
        <Box
            bgcolor="#EBEBE1"
            sx={{
                pt: {
                    xs: 2,
                    md: 8
                },
                pb: {
                    xs: 2,
                    md: 14
                }
            }}
        >
            <Layout.Content>
                <ThemeProvider theme={darkTheme}>
                    <ContactForm />
                </ThemeProvider>
            </Layout.Content>
        </Box>
        <Box bgcolor="info.main">
            <ContentStrip
                image={
                    <Box
                        component="img"
                        src={CityRoad}
                        sx={{
                            height: { xs: "auto", md: "188px" },
                            width: {
                                xs: "100%",
                                sm: "223px",
                                md: "420px"
                            }
                        }}
                        alt="City Road Communications"
                    />
                }
            >
                <Typography fontWeight={500} sx={{ fontSize: "1.875rem" }}>
                    Press enquiries
                </Typography>
                <Typography fontFamily="Hind" component="p" variant="body1" sx={{ my: 3 }}>
                    Please direct all media and press enquiries to City Road Communications.
                </Typography>
                <Typography fontFamily="Hind" component="p" variant="body1" mb={4}>
                    Email:{" "}
                    <Link
                        color="text.primary"
                        href="mailto:dominic@cityroadcomms.co.uk"
                        sx={{ fontWeight: "500" }}
                    >
                        dominic@cityroadcomms.co.uk
                    </Link>
                </Typography>
            </ContentStrip>
        </Box>
    </Layout>
);

export const Head = () => (
    <SEO
        title="ModaMortgages: Get in touch"
        description="Drop us a message and our Relationship Team will get back to you."
    />
);

export default GetInTouch;
