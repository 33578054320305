import * as React from "react";
import { useFormContext } from "react-hook-form";
import Alert from "@mui/material/Alert";

const SubmissionErrorMessage = () => {
    const {
        formState: { errors, isSubmitted }
    } = useFormContext();

    if (!(isSubmitted && errors?.submissionError)) return null;

    return (
        <Alert severity="warning" color="error" variant="filled" sx={{ mb: 4, mt: -2 }}>
            Sorry, something went wrong. Please try again.
        </Alert>
    );
};

export default SubmissionErrorMessage;
