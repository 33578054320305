import * as React from "react";
import PropTypes from "prop-types";
import MuiFormHelperText from "@mui/material/FormHelperText";
import WarningIcon from "@mui/icons-material/Warning";

const FormHelperText = React.forwardRef(({ children, error, ...rest }) => (
    <MuiFormHelperText {...{ ...rest, error }}>
        {error && <WarningIcon sx={{ verticalAlign: "sub" }} />} {children}
    </MuiFormHelperText>
));

FormHelperText.propTypes = {
    children: PropTypes.node.isRequired,
    error: PropTypes.bool
};

FormHelperText.defaultProps = {
    error: false
};

export default FormHelperText;
