import * as React from "react";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useFormContext } from "react-hook-form";
import isEmail from "validator/es/lib/isEmail";
import request from "superagent";
import ReCAPTCHA from "react-google-recaptcha";

import Honeypot from "@chetwoodfinancial/pws-components/Honeypot";

import FormHelperText from "../FormHelperText";
import TextField from "../TextField";

// @todo copy pasted from chetwood-pws - move to shared location
// @see https://stackoverflow.com/a/11792324 - modified to allow trailing whitespace
const REGEXP_PHONE_NUMBER =
    // eslint-disable-next-line no-useless-escape
    /^(?:(?:\(?(?:0(?:0|11)\)?[\s-]?\(?|\+)44\)?[\s-]?(?:\(?0\)?[\s-]?)?)|(?:\(?0))(?:(?:\d{5}\)?[\s-]?\d{4,5})|(?:\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3}))|(?:\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4})|(?:\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}))(?:[\s-]?(?:x|ext\.?|\#)\d{3,4})?\s*$/;

const { useCallback, useRef } = React;

const nameRules = (firstLast) => ({
    required: `${firstLast} name is required`,
    maxLength: {
        value: 35,
        message: `${firstLast} name needs to be 2-35 characters long`
    },
    minLength: {
        value: 2,
        message: `${firstLast} name needs to be 2-35 characters long`
    },
    pattern: {
        value: /^[a-zA-Z-' ]*$/,
        message: "Name must only include letters a to z, hyphens, spaces and apostrophes"
    }
});

const ContactForm = () => {
    const recaptchaRef = useRef();

    const {
        register: registerField,
        handleSubmit,
        formState: { errors },
        setError,
        watch,
        clearErrors
    } = useFormContext();

    const register = useCallback(
        (name, ...rest) => ({
            ...registerField(name, ...rest),
            error: !!errors[name],
            helperText: errors[name]?.message
        }),
        [errors, registerField]
    );

    const onSubmit = useCallback(
        async (formValues) => {
            const recaptchaToken = await recaptchaRef.current.executeAsync();

            return request
                .post("/.netlify/functions/enquiry")
                .send({ ...formValues, recaptchaToken })
                .catch((err) => {
                    setError("submissionError", { type: "server", message: err.message });
                });
        },
        [setError]
    );

    const checkCaptcha = useCallback(() => {
        recaptchaRef.current.executeAsync().then((value) => {
            if (value.length < 1) {
                setError("recaptchaValue", {
                    type: "submissionError",
                    message: "Please check reCAPTCHA"
                });
            }
        });
    }, [setError]);

    const watchPrivacyPolicyAgreement = watch("privacyPolicyAgreement", false);
    const watchMarketingAgreement = watch("marketingAgreement", false);

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                checkCaptcha();
                clearErrors("submissionError");
                return handleSubmit(onSubmit)(e);
            }}
        >
            <Grid container={true} spacing={4}>
                <Grid item={true} xs={12} sm={6}>
                    <TextField label="First name" {...register("firstName", nameRules("First"))} />
                </Grid>
                <Grid item={true} xs={12} sm={6}>
                    <TextField label="Last name" {...register("lastName", nameRules("Last"))} />
                </Grid>
                <Grid item={true} xs={12} sm={6}>
                    <TextField
                        label="Job title"
                        {...register("jobTitle", {
                            required: "Job title is required"
                        })}
                    />
                </Grid>
                <Grid item={true} xs={12} sm={6}>
                    <TextField
                        type="tel"
                        autoComplete="tel-local"
                        label="Phone number"
                        {...register("phoneNumber", {
                            required: "Telephone number is required",
                            pattern: {
                                value: REGEXP_PHONE_NUMBER,
                                message: "Please enter a valid telephone number"
                            }
                        })}
                    />
                </Grid>
                <Grid item={true} xs={12} sm={6}>
                    <TextField
                        label="Company name"
                        {...register("companyName", {
                            required: "Company name is required"
                        })}
                    />
                </Grid>
                <Grid item={true} xs={12} sm={6}>
                    <TextField
                        type="email"
                        autoComplete="email"
                        label="Email address"
                        {...register("email", {
                            required: "Email address is required",
                            validate: (value) =>
                                !isEmail(value) ? "Please enter a valid email address." : undefined
                        })}
                    />
                </Grid>

                <Grid item={true} xs={12}>
                    <Typography fontSize="1.125rem" mb={1} fontFamily="Hind">
                        Stay up to date with the latest news and product information from
                        ModaMortgages.
                    </Typography>
                    <FormControlLabel
                        control={
                            <Checkbox
                                sx={{ color: "#201A1C" }}
                                {...register("marketingAgreement")}
                                checked={watchMarketingAgreement}
                            />
                        }
                        label="Please tick here if you wish to receive marketing communications."
                        sx={{ fontFamily: "Hind" }}
                    />
                </Grid>

                <Grid item={true} xs={12} sx={{ pt: "16px !important", pb: 2 }}>
                    <Typography fontSize="1.125rem" mb={1} fontFamily="Hind">
                        If you change your mind you can unsubscribe at any time by telling us or
                        following the unsubscribe links in emails that you receive. For more
                        information, please see our{" "}
                        <Link href="/privacypolicy" sx={{ fontWeight: "500" }}>
                            Privacy Policy.
                        </Link>
                    </Typography>
                    <FormControlLabel
                        control={
                            <Checkbox
                                sx={{ color: "#201A1C" }}
                                {...register("privacyPolicyAgreement", {
                                    required: "You must agree to the Privacy Policy"
                                })}
                                checked={watchPrivacyPolicyAgreement}
                            />
                        }
                        label="Please tick to confirm you have read and agree with our Privacy Policy."
                        sx={{ fontFamily: "Hind" }}
                    />
                    {errors.privacyPolicyAgreement && (
                        <FormHelperText error={!!errors.privacyPolicyAgreement}>
                            {errors.privacyPolicyAgreement.message}
                        </FormHelperText>
                    )}
                </Grid>
            </Grid>
            <Honeypot {...register("communication_preference_agreement")} />

            <ReCAPTCHA
                sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY}
                ref={recaptchaRef}
                size="invisible"
                onChange={() => clearErrors("recaptchaValue")}
            />
            {errors.recaptchaValue && (
                <FormHelperText error={!!errors.recaptchaValue}>
                    {errors.recaptchaValue.message}
                </FormHelperText>
            )}
            <Button
                color="secondary"
                type="submit"
                sx={{
                    mt: 2,
                    width: {
                        xs: "100%",
                        sm: "auto"
                    }
                }}
            >
                Send
            </Button>
        </form>
    );
};

export default ContactForm;
