import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";

import theme from "../../gatsby-theme-material-ui-top-layout/theme";
import SuccessImage from "../../images/Success.svg";

import Form from "./Form";
import LoadingSpinnerOverlay from "./LoadingSpinnerOverlay";
import SubmissionErrorMessage from "./SubmissionErrorMessage";

const ContactFormContainer = (props) => {
    const methods = useForm({ mode: "onBlur" });
    const {
        formState: { isSubmitSuccessful }
    } = methods;

    const typographyStyles = {
        fontWeight: "500",
        fontFamily: "Montserrat",
        variant: "body1",
        lineHeight: "125%",
        color: "text.primary",
        fontSize: {
            xs: "1.375rem",
            sm: "1.875rem"
        },
        mb: 4
    };

    return (
        <>
            {!isSubmitSuccessful ? (
                <FormProvider {...methods}>
                    <Box position="relative">
                        <Container disableGutters={true} maxWidth="md" sx={{ ml: 0 }}>
                            <Typography {...typographyStyles}>
                                Complete this short form to find out more about ModaMortgages, and
                                our Relationship Team will be in touch ahead of launch
                            </Typography>
                            <SubmissionErrorMessage />
                            <Form />
                        </Container>
                        <LoadingSpinnerOverlay />
                    </Box>
                </FormProvider>
            ) : (
                <ThemeProvider {...{ theme }}>
                    <Typography {...typographyStyles}>
                        Thank you for your enquiry. We’ll be in touch shortly.
                    </Typography>
                    <Box display="flex" justifyContent="center" pt={12} pb={6}>
                        <Box component="img" src={SuccessImage} alt="null" height="80px" />
                    </Box>
                </ThemeProvider>
            )}
        </>
    );
};

export default ContactFormContainer;
